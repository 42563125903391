<template>
  <div class="block-article">
    <div class="block-article-content" v-if="post">
      <router-link
        v-if="post.id"
        :to="{ name: 'post', params: { id: post.id, slug: post.slug || '' } }"
        class="w-inline-block"
      >
        <h1 class="headline-article headline-link">{{ post.title }}</h1>
      </router-link>
      <div class="text-content" v-if="post.excerpt">
        <span v-html="excerpt"></span>
        <p style="margin-top: 10px">
          <router-link
            v-if="post.slug"
            :to="{ name: 'post', params: { slug: post.slug } }"
            ><i class="ui icon long arrow alternate right"></i>Weiterlesen
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "postExcerpt",
  props: ["post"],
  computed: {
    excerpt() {
      if (this.post && this.post.excerpt && this.post.excerpt.length > 100) {
        return `${this.post.excerpt}…`;
      }
      return this.post.excerpt;
    },
  },
};
</script>
