<template>
  <div class="block-date">
    <router-link
      :to="{ name: 'event', params: { id: event.id, slug: event.slug } }"
      v-if="event.title"
      class="link-block-3 w-inline-block"
    >
      <h4 class="heading-date" style="margin-bottom: 0">
        {{ event.title }}
      </h4>
    </router-link>
    <!-- [START] Time for non-recurring, full day event -->
    <div
      class="text-meta text-meta-date"
      v-if="event.isAllDay && !event.isMultiDay"
    >
      <i class="calendar alternate outline icon"></i>

      {{ event.startAt | moment("dd, DD. MMM YYYY") }}
    </div>
    <!-- [END] Time for non-recurring, full day event -->
    <!-- [START] Time for non-recurring, full day event -->
    <div class="text-meta text-meta-date" v-else-if="event.isAllDay">
      <i class="calendar alternate outline icon"></i>

      {{ event.startAt | moment("dd, DD. MMM YYYY") }} -
      {{ event.endAt.seconds | moment("dd, DD. MMM YYYY") }}
    </div>
    <!-- [END] Time for non-recurring, full day event -->
    <!-- [START] Time for recurring, non cancelled event -->
    <div
      class="text-meta text-meta-date"
      v-else-if="event.isRecurring && event.nextEvents && event.nextEvents[0]"
    >
      <span :class="{ 'text--deleted': isNextEventCancelled }"
        ><i class="calendar alternate outline icon"></i>
        {{ recurringStartDate | moment("dd, DD. MMM YYYY") }} um
        {{ event.startAt | moment("HH:mm") }} Uhr</span
      >
    </div>
    <!-- [END] Time for recurring, non cancelled event -->
    <!-- [START] Time for non-recurring, non cancelled event -->
    <div class="text-meta text-meta-date" v-else>
      <i class="calendar alternate outline icon"></i>

      {{ event.startAt | moment("dd, DD. MMM YYYY") }} um
      {{ event.startAt | moment("HH:mm") }} Uhr
    </div>
    <!-- [END] Time for non-recurring, non cancelled event -->
    <!-- [START] Time for recurring, cancelled event -->
    <div
      class="text-date-content"
      v-if="isNextEventCancelled"
      style="margin-top: 10px"
    >
      <div class="ui message orange small">
        Der kommende Kurstermin am
        <strong>
          {{ recurringStartDate | moment("dddd, DD. MMM YYYY") }}</strong
        >
        wurde leider abgesagt.
      </div>
    </div>
    <!-- [END] Time for recurring, cancelled event -->
    <div
      class="text-date-content"
      v-if="event.excerpt && !isNextEventCancelled"
    >
      <span v-html="event.excerpt"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "eventExcerpt",
  props: ["event"],
  computed: {
    recurringStartDate() {
      if (this.isNextEventCancelled) {
        return this.event.nextEvents[0].startAt;
      }
      return this.event.nextEvent.startAt;
    },
    isNextEventCancelled() {
      return (
        this.event &&
        this.event.isRecurring &&
        this.event.nextEvents &&
        this.event.nextEvents.length > 0 &&
        this.event.nextEvents[0].cancelled
      );
    },
  },
};
</script>
