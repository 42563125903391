<template>
  <div class="block-article" v-if="post" :data-post-id="post.id">
    <router-link
      v-if="post.id"
      :to="{ name: 'post', params: { id: post.id, slug: post.slug } }"
    >
      <img
        v-if="post.imageUrl"
        :src="post.imageUrl"
        :alt="post.title"
        class="image-article"
        style="max-height: 250px; object-fit: cover"
      />
    </router-link>
    <div class="block-article-content">
      <div class="text-meta">
        {{ post.created.seconds | moment("DD. MMMM YYYY") }}
        <span v-if="post.author && post.author.fullName"
          >· {{ post.author.fullName }}</span
        >
      </div>
      <router-link
        v-if="post.id"
        :to="{ name: 'post', params: { id: post.id, slug: post.slug } }"
      >
        <h1 class="headline-article headline-link">{{ post.title }}</h1>
      </router-link>
      <div class="text-content w-richtext" v-if="post.excerpt">
        <span v-html="excerpt"></span>
        <p style="margin-top: 10px">
          <router-link
            v-if="post.slug"
            :to="{ name: 'post', params: { slug: post.slug } }"
            ><i class="ui icon long arrow alternate right"></i>Weiterlesen
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "postFull",
  props: ["post"],
  computed: {
    excerpt() {
      if (this.post && this.post.excerpt && this.post.excerpt.length > 100) {
        return `${this.post.excerpt}…`;
      }
      return this.post.excerpt;
    },
  },
};
</script>
