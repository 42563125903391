<template>
  <div class="section-footer">
    <div class="container-content">
      <div class="w-row">
        <div class="w-col w-col-4" style="padding-bottom: 1.5em">
          <div>
            <h4 class="block-headline">KONTAKT</h4>
            <p class="paragraph">
              Skiklub Oestertal 1956 e. V.
              <br />Am Felde 8, 58840 Plettenberg
            </p>
            <p class="paragraph">info@skiklub-oestertal.de</p>
          </div>
          <div style="margin-top: 16px">
            <a href="https://fb.com/skikluboestertal" target="_blank"
              ><img
                src="./../assets/images/icon-fb.png"
                alt="Facebook Icon"
                width="30px"
            /></a>
            <a href="https://instagram.com/skikluboestertal" target="_blank"
              ><img
                src="./../assets/images/icon-ig.png"
                alt="Instagram Icon"
                width="30px"
                style="margin-left: 12px"
            /></a>
            <a
              href="https://chat.whatsapp.com/H0dzx5RHZz7J8BWjtaiZNj"
              target="_blank"
              ><img
                src="./../assets/images/icon-whatsapp.svg"
                alt="WhatsApp Icon"
                width="30px"
                style="margin-left: 12px"
            /></a>
            <a href="https://t.me/skikluboestertal" target="_blank"
              ><img
                src="./../assets/images/icon-telegram.svg"
                alt="Telegram Icon"
                width="30px"
                style="margin-left: 12px"
            /></a>
          </div>
        </div>
        <div class="w-col w-col-3">
          <h4 class="block-headline">Seiten</h4>
          <ul class="footer-page-list w-list-unstyled">
            <li class="list-item">
              <router-link :to="{ name: 'about' }" class="footer-link"
                >Über den Verein</router-link
              >
            </li>
            <li class="list-item">
              <router-link :to="{ name: 'activities' }" class="footer-link"
                >Angebote</router-link
              >
            </li>
            <li class="list-item">
              <router-link :to="{ name: 'events' }" class="footer-link"
                >Termine</router-link
              >
            </li>
            <li class="list-item">
              <router-link :to="{ name: 'member' }" class="footer-link"
                >Mitgliedschaft</router-link
              >
            </li>
            <li class="list-item">
              <router-link :to="{ name: 'contact' }" class="footer-link"
                >Kontakt</router-link
              >
            </li>
            <li class="list-item">
              <router-link :to="{ name: 'profile' }" class="footer-link"
                >Mein Profil</router-link
              >
            </li>
          </ul>
        </div>
        <div class="w-col w-col-3">
          <h4 class="block-headline">RECHTLICHES</h4>
          <ul class="footer-page-list w-list-unstyled">
            <li class="list-item">
              <router-link :to="{ name: 'data-policy' }" class="footer-link"
                >Datenschutz</router-link
              >
            </li>
            <li class="list-item">
              <router-link :to="{ name: 'terms' }" class="footer-link"
                >Nutzungsbedingungen</router-link
              >
            </li>
            <li class="list-item">
              <router-link :to="{ name: 'imprint' }" class="footer-link"
                >Impressum</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerHome",
};
</script>
