<template>
  <div class="section-header section-header-subpage">
    <div class="section-header-image">
      <div class="container-content block-logo">
        <router-link
          :to="{ name: 'home' }"
          class="block-header-logo header-logo-index w-clearfix"
        >
          <img
            src="@/assets/images/logo.png"
            width="65"
            alt="Logo"
            class="image-3"
          />
          <h1 class="heading-logo">Skiklub Oestertal 1956 e.V.</h1>
        </router-link>
      </div>
    </div>

    <NavBar />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";

export default {
  name: "headerSubpage",
  components: {
    NavBar,
  },
};
</script>
