import { firestoreAction } from "vuexfire";
import { db } from "@/firebase";
import { firebase } from "@firebase/app";
import "@firebase/auth";

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  loading: false,
  list: [],
};

/**
 * Getters
 */
export const getters = {
  loading: state => state.loading,
  list: state => state.list,
  subscriptionByEventId: state => eventId => {
    return state.list.find(subscription => {
      return subscription.eventId === eventId;
    });
  },
};

/**
 * Actions
 */
export const actions = {
  /**
   * Add subscription for data from Firebase
   */
  setRef: firestoreAction(async ({ bindFirestoreRef, commit }) => {
    const user = await firebase.auth().currentUser;
    if (!user) {
      return null;
    }

    commit("SET_LOADING", true);

    bindFirestoreRef(
      "list",
      db
        .collection("subscribers")
        .where("userId", "==", user.uid)
        .orderBy("created")
        .limit(15),
      {
        reset: () => false,
      }
    )
      .catch(error => console.error("Error receiving subscriptions", error))
      .finally(() => commit("SET_LOADING", false));
  }),

  /**
   * Adds a new subscription for a given user
   */
  add: firestoreAction((_, { event }) => {
    const user = firebase.auth().currentUser;
    if (!user) {
      return null;
    }

    return db
      .collection("subscribers")
      .add({
        created: firebase.firestore.FieldValue.serverTimestamp(),
        updated: firebase.firestore.FieldValue.serverTimestamp(),
        userId: user.uid,
        eventId: event.id,
        event: db.collection("events").doc(event.id),
        sms: null,
        email: user.email,
      })
      .catch(error => console.error("Error adding subscription", error));
  }),

  /**
   * Updates an existing subscription for a given user
   */
  updateChannel: firestoreAction((_, { subscription, channel, value }) => {
    const update = {};
    update[`${channel}`] = value ? value : false;
    update["updated"] = firebase.firestore.FieldValue.serverTimestamp();

    return db
      .collection("subscribers")
      .doc(subscription.id)
      .update(update)
      .catch(error => console.error("Error updating subscription", error));
  }),

  /**
   * Deletes an existing subscription for a given user
   */
  delete: firestoreAction((_, { subscription }) =>
    db
      .collection("subscribers")
      .doc(subscription.id)
      .delete()
      .catch(error => console.error("Error deleting subscription", error))
  ),
};

/**
 * Mutations
 */
export const mutations = {
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
};
