<template>
  <div
    v-if="loading"
    class="block-date"
    style="
      text-align: center;
      box-shadow: none;
      color: #747474;
      font-size: 14px;
      background-color: transparent;
    "
  >
    <p>Lade Neuigkeiten …</p>
  </div>
  <div v-else>
    <PostFull v-if="featuredPost" :post="featuredPost" />
    <PostExcerpt v-if="posts && posts.length > 1" :post="posts[1]" />
    <PostExcerpt v-if="posts && posts.length > 2" :post="posts[2]" />

    <router-link
      :to="{ name: 'posts' }"
      class="button basic uppercase fluid centered"
      >Weitere Neuigkeiten ansehen</router-link
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PostFull from "@/components/posts/PostFull";
import PostExcerpt from "@/components/posts/PostExcerpt";

export default {
  name: "postList",
  components: {
    PostFull,
    PostExcerpt,
  },
  computed: {
    ...mapGetters({
      loading: "post/loading",
      posts: "post/list",
      featuredPost: "post/featured",
    }),
  },
};
</script>
