<template>
  <div class="section-header">
    <div class="section-header-image">
      <div
        class="container-content block-logo"
        :class="{ 'block-logo--home': isHome }"
      >
        <router-link
          :to="{ name: 'home' }"
          class="block-header-logo header-logo-index w-clearfix"
        >
          <img
            src="@/assets/images/logo.png"
            width="65"
            alt="Logo"
            class="image-3"
          />
          <h1 class="heading-logo">Skiklub Oestertal 1956 e.V.</h1>
        </router-link>
      </div>
    </div>
    <NavBar />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";

export default {
  name: "header--home",
  components: {
    NavBar,
  },
  computed: {
    isHome() {
      return this.$route.name === "home";
    },
  },
};
</script>

<style>
@media (max-width: 479px) {
  .block-logo--home {
    padding-top: 120px;
  }
}
</style>
