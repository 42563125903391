import Default from "@/layouts/Default";
import NoSidebar from "@/layouts/NoSidebar";
import { firebase } from "@firebase/app";
import "@firebase/auth";
import "@firebase/firestore";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import LogRocket from "logrocket";
import Vue from "vue";
import AsyncComputed from "vue-async-computed";
import Meta from "vue-meta";
import Toasted from "vue-toasted";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store/";

// Init MomentJS for timestamps
const moment = require("moment");
require("moment/locale/de");
require("moment-recur");
require("moment-timezone");
Vue.use(require("vue-moment"), {
  moment,
});

// Toasted plugin for toast messages
Vue.use(Toasted);
Vue.use(AsyncComputed);

// Init Meta plugin for SEO extensions
Vue.use(Meta);

// Import layouts
Vue.component("default-layout", Default);
Vue.component("no-sidebar-layout", NoSidebar);

// Load external css files
require("@/assets/css/normalize.css");
require("@/assets/css/webflow.css");
require("@/assets/css/style.css");
require("@/assets/css/buttons.css");
require("@/assets/css/responsive-mobile.css");

Vue.config.productionTip = false;

// Init Sentry
if (process.env.NODE_ENV === "production") {
  LogRocket.init("ucv2eu/sko", {
    release: process.env.VUE_APP_RELEASE,
  });

  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DNS,
    release: process.env.VUE_APP_RELEASE,
    environment:
      process.env.NODE_ENV !== "production" ? "development" : "production",
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
  });

  Sentry.configureScope(scope => {
    scope.addEventProcessor(async event => {
      if (LogRocket && LogRocket.sessionURL && event && event.extra) {
        event.extra.sessionURL = LogRocket.sessionURL;
      }
      return event;
    });
  });
}

// Initialize Vue
let app;
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount("#app");
  }
});
