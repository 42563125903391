<template>
  <div>
    <div
      v-if="loading"
      class="block-date"
      style="
        text-align: center;
        box-shadow: none;
        color: #747474;
        font-size: 14px;
        background-color: transparent;
      "
    >
      <p>Lade Kurstermine …</p>
    </div>
    <div v-else-if="events && events.length > 0">
      <div v-for="event in events" :key="event.id">
        <EventExcerpt :event="event" />
      </div>
    </div>
    <div v-else>
      <div
        class="block-date"
        style="
          text-align: center;
          color: rgba(51, 51, 51, 0.67);
          box-shadow: none;
          background-color: transparent;
        "
      >
        <i
          class="cloud sun rain big icon"
          style="
            margin-bottom: 20px;
            margin-top: -5px;
            color: rgba(51, 51, 51, 0.45);
          "
        ></i>
        <div class="text-meta">
          Aktuell sind keine regelmäßigen Termine geplant
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import EventExcerpt from "@/components/events/EventExcerpt";

export default {
  name: "CourseList",
  components: {
    EventExcerpt,
  },
  computed: {
    ...mapGetters({
      loading: "event/loading",
      events: "event/recurring",
    }),
  },
};
</script>
