import { db, Timestamp } from "@/firebase";
import orderBy from "lodash/orderBy";
import { firestoreAction } from "vuexfire";

const moment = require("moment");
require("moment/locale/de");

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  loading: false,
  list: [],
};

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

async function generateEventList(list) {
  if (!list || list.count === 0) {
    return null;
  }

  // console.log("[generateEventList] Input list", list);

  let events = [];

  await asyncForEach(list, async event => {
    // console.log("[generateEventList] Checking event", event.title);
    if (!event.isRecurring) {
      // console.log(
      //   "[generateEventList] Event is not recurring, adding to array."
      // );
      const startAt = moment(event.startAt.toDate()).format();
      // console.log("[generateEventList] Added date", startAt);
      events.push({
        ...event,
        id: event.id,
        startAt: startAt,
      });
    } else if (event && moment.unix(event.endAt.seconds) < moment()) {
      // If it's a recurring event which has been stopped in the past.
      // Add the event to the list to ensure that course members can still access it.
      events.push({
        ...event,
        id: event.id,
        startAt: null,
        nextEvents: null,
        nextEvent: null,
      });
    } else {
      // If it's a recurring event, get next event date.

      const nextEvents = await generateNextEventsForCourse(event);
      if (!nextEvents || nextEvents.length === 0) {
        return;
      }
      //console.log("[generateEventList] nextEvents", nextEvents);

      const startAt = moment(nextEvents[0].startAt).tz("Europe/Berlin");
      const rootStartAt = event.startAt.toDate();

      const transformedNextEventStartAt = startAt
        .set({
          year: startAt.get("year"),
          day: startAt.get("day") - 1,
          month: startAt.get("month"),
          hour: moment(rootStartAt).get("hour"),
          minute: moment(rootStartAt).get("minute"),
          second: moment(rootStartAt).get("second"),
        })
        .format();

      // console.log(
      //   "[generateEventList] Added date",
      //   transformedNextEventStartAt
      // );

      const nextEvent = await getNextEventForCourse(nextEvents);
      console.log(nextEvents);

      events.push({
        ...event,
        id: event.id,
        startAt: transformedNextEventStartAt,
        nextEvents: nextEvents,
        nextEvent,
      });
    }
  });
  return events;

  /*
  for (const event of list) {
    console.log("[generateEventList] Checking event", event.title);
    if (!event.isRecurring) {
      console.log(
        "[generateEventList] Event is not recurring, adding to array."
      );
      const startAt = moment(event.startAt.toDate()).format();
      // console.log("[generateEventList] Added date", startAt);
      events.push({
        ...event,
        id: event.id,
        startAt: startAt,
      });
    } else if (event && moment.unix(event.endAt.seconds) < moment()) {
      // If it's a recurring event which has been stopped in the past.
      // Add the event to the list to ensure that course members can still access it.
      events.push({
        ...event,
        id: event.id,
        startAt: null,
        nextEvents: null,
        nextEvent: null,
      });
    } else {
      console.log(
        "[generateEventList] Event is recurring, generating next events…"
      );
      // If it's a recurring event, get next event date.

      const nextEvents = await generateNextEventsForCourse(event);
      if (!nextEvents || nextEvents.length === 0) {
        return;
      }
      console.log("[generateEventList] nextEvents", nextEvents);

      const startAt = moment(nextEvents[0].startAt).tz("Europe/Berlin");
      const rootStartAt = event.startAt.toDate();

      const transformedNextEventStartAt = startAt
        .set({
          year: startAt.get("year"),
          day: startAt.get("day") - 1,
          month: startAt.get("month"),
          hour: moment(rootStartAt).get("hour"),
          minute: moment(rootStartAt).get("minute"),
          second: moment(rootStartAt).get("second"),
        })
        .format();

      console.log(
        "[generateEventList] Added date",
        transformedNextEventStartAt
      );

      const newEvent = {
        ...event,
        id: event.id,
        startAt: transformedNextEventStartAt,
        nextEvents: nextEvents,
        nextEvent: getNextEventForCourse(nextEvents),
      };
      events.push(newEvent);
      console.log("[generateEventList] Added event", newEvent);
    }
  }
  */

  // console.log("[generateEventList] Events", events);

  // return events;
  // return orderBy(events, ["startAt"], ["asc"]);
}

function getNextEventForCourse(events) {
  const filteredEvents = events.filter(event => {
    // console.log("Checking event", event);
    return !event.cancelled && !event.deleted;
  });
  // console.log("[getNextEventForCourse] filteredEvents", filteredEvents[0]);
  return filteredEvents.length > 0 ? filteredEvents[0] : null;
}

function getNextDates(startDate, endDate) {
  let dates = [];
  let current = moment(startDate);
  let targetWeekday = current.weekday();
  let targetWeekOfMonth = Math.floor(current.date() / 7);

  while (current.isBefore(endDate)) {
    dates.push(current.clone());
    current.add(1, "months").startOf("month");

    // Adjust the date to the same weekday and week of the month
    let weekCounter = 0;
    while (
      current.weekday() !== targetWeekday ||
      weekCounter < targetWeekOfMonth
    ) {
      if (current.weekday() === targetWeekday) {
        weekCounter++;
      }
      current.add(1, "day");
    }
  }

  return dates;
}

async function generateNextEventsForCourse(event) {
  return Promise.resolve().then(async () => {
    if (
      !event ||
      (event && !event.startAt) ||
      (event && !event.endAt) ||
      (event && !event.recurrenceWeekdays)
    ) {
      return;
    }
    var startDate = event.startAt.seconds
      ? moment.unix(event.startAt.seconds)
      : moment(event.startAt);
    var endDate = moment.unix(event.endAt.seconds);
    var newEndDate = moment(endDate).add(1, "days");

    const recurrenceWeekdays = $.trim(event.recurrenceWeekdays);

    var condition;
    var nextDates = [];
    if (event.recurrencePeriod == "weekly") {
      condition = moment
        .recur({
          start: startDate,
          end: newEndDate,
        })
        .every(recurrenceWeekdays.split(",").map(Number))
        .daysOfWeek();
      nextDates = condition.all();
    } else if (event.recurrencePeriod == "monthly") {
      nextDates = getNextDates(startDate, endDate);
    }

    let nextEvents = [];
    await nextDates.forEach(async nextDate => {
      // Override hours for next date
      nextDate = nextDate.set({ hour: 0 });

      // Skip dates later than today
      const diff = nextDate.diff(moment(), "days");
      if (diff < 0) {
        return;
      }

      // Check if next date is listed in the exception list and won't be displayed
      let exception;
      if (event.exceptions && event.exceptions.length > 0) {
        exception = await event.exceptions.find(
          exception =>
            moment(exception).toISOString() === moment(nextDate).toISOString()
        );
      }

      let cancelledEvent;
      // console.log("Cancelled Events:", event.cancelled);
      for (const key in event.cancelled) {
        // console.log("[Cancelled Events] Key", key);
        // console.log(
        //   "[Cancelled Events] NextDate",
        //   moment(nextDate).toISOString()
        // );
        // console.log(
        //   "[Cancelled Events] Equal?",
        //   key == moment(nextDate).toISOString() ? "YES" : "NO"
        // );

        if (key == moment(nextDate).toISOString()) {
          cancelledEvent = {
            startAt: nextDate,
            timestamp: moment(nextDate).format("dddd, DD. MMMM YYYY"),
            reason: event.cancelled[key],
            cancelled: true,
            deleted: exception ? true : false,
          };
        }
      }
      if (!cancelledEvent) {
        nextEvents.push({
          startAt: nextDate,
          timestamp: moment(nextDate).format("dddd, DD. MMMM YYYY"),
          cancelled: false,
          deleted: exception ? true : false,
        });
      } else {
        nextEvents.push(cancelledEvent);
      }
    });

    // console.log("Next Events:", nextEvents);

    return nextEvents;
  });
}

/**
 * Getters
 */
export const getters = {
  loading: state => state.loading,
  list: state => orderBy(state.list, ["startAt"], ["asc"]),
  recurring: state => {
    if (state.list && state.list.length > 0) {
      const events = state.list.reduce((filtered, option) => {
        if (option.isRecurring && option.startAt) filtered.push(option);
        return filtered;
      }, []);
      return orderBy(events, ["startAt"], ["asc"]);
    }
    return null;
  },
  nonRecurring: state => {
    if (state.list && state.list.length > 0) {
      return state.list.filter(
        event =>
          !event.isRecurring &&
          moment(moment.unix(event.endAt.seconds)).isSameOrAfter(
            moment(),
            "day"
          )
      );
    }
  },
  featured: state => {
    if (state.list && state.list.length > 0) {
      return state.list[0];
    }
  },
  eventById: state => id => {
    return state.list.find(event => {
      return event.id === id;
    });
  },
  eventBySlug: state => slug => {
    return state.list.find(event => {
      return event.slug === slug;
    });
  },
  relatedEventsById: state => id => {
    if (state.list && state.list.length > 0) {
      return state.list.filter(event => {
        return (
          event.id !== id &&
          event.endAt &&
          !event.isRecurring &&
          moment(moment.unix(event.endAt.seconds)).isSameOrAfter(
            moment(),
            "day"
          )
        );
      });
    }
  },
  relatedEventsByTag: state => tag => {
    if (state.list && state.list.length > 0) {
      const events = state.list.reduce((filtered, option) => {
        if (
          option.title.toLowerCase().includes(tag.toLowerCase()) &&
          option.startAt &&
          moment(moment.unix(option.endAt.seconds)).isSameOrAfter(
            moment(),
            "day"
          )
        )
          filtered.push(option);
        return filtered;
      }, []);
      return orderBy(events, ["startAt"], ["asc"]);
    }
  },
};

/**
 * Actions
 */
export const actions = {
  setRef: firestoreAction(
    async ({ state, dispatch, bindFirestoreRef, commit }) => {
      commit("SET_LOADING", true);

      const now = Timestamp.fromDate(new Date()).toDate();

      await bindFirestoreRef(
        "list",
        db
          .collection("events")
          .where("visibility", "==", "published")
          .where("endAt", ">=", now)
          .orderBy("endAt")
          .orderBy("startAt")
          .limit(50),
        {
          reset: () => false,
        }
      )
        .then(() => dispatch("setEvents", state.list))
        .catch(error => console.error("Error receiving events", error))
        .finally(() => commit("SET_LOADING", false));
    }
  ),
  async setEvents({ commit }, events) {
    const eventList = await generateEventList(events);

    commit("SET_EVENTS", eventList);
  },
};

/**
 * Mutations
 */
export const mutations = {
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },

  async SET_EVENTS(state, events) {
    state.list = events;
  },
};
