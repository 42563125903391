import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from "vuexfire";

import * as post from "@/store/modules/post";
import * as event from "@/store/modules/event";
import * as user from "@/store/modules/user";
import * as subscription from "@/store/modules/subscription";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  mutations: {
    ...vuexfireMutations,
  },
  modules: {
    post,
    event,
    user,
    subscription,
  },
});
