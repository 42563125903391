<template>
  <div id="app">
    <div class="main">
      <component :is="layout">
        <router-view />
      </component>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

const defaultLayout = "default";

export default {
  name: "App",
  data() {
    return {
      showUpdateOverlay: false,
    };
  },
  metaInfo: {
    title: "Startseite",
    titleTemplate: "%s | Skiklub Oestertal 1956 e.V.",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Wir bringen dich seit 1956 als Bewegung. Erlebe unsere Sportverein von allen Seiten – im Sommer und Winter!",
      },
      // OpenGraph data
      {
        property: "og:title",
        content: "Startseite | Skiklub Oestertal 1956 e.V.",
      },
      { property: "og:site_name", content: "Skiklub Oestertal 1956 e.V." },
      { property: "og:type", content: "website" },
      {
        property: "og:url",
        content: "https://www.skiklub-oestertal.de",
      },
      {
        property: "og:description",
        content:
          "Wir bringen dich seit 1956 als Bewegung. Erlebe unsere Sportverein von allen Seiten – im Sommer und Winter!",
      },
    ],
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
    isUpdateOverlayVisible() {
      return this.showUpdateOverlay;
    },
  },
  created() {
    this.$moment.tz.setDefault("Europe/Berlin");

    this.addUpdateListener();

    this.$store
      .dispatch("post/setRef")
      .then(() => this.$store.dispatch("event/setRef"))
      .then(() => this.$store.dispatch("user/setRef"))
      .then(() => this.$store.dispatch("subscription/setRef"));
  },
  methods: {
    addUpdateListener() {
      if ("serviceWorker" in navigator) {
        if ("BroadcastChannel" in self) {
          // BroadcastChannel API supported!
          // eslint-disable-next-line no-undef
          const swListener = new BroadcastChannel("swListener");
          swListener.onmessage = event => {
            if (event.data && event.data.cmd === "installed") {
              this.showNewVersionAvailableToast();
            }
          };
        } else {
          // Wait for update message by service worker for new version
          self.addEventListener("message", evt => {
            if (evt.data && evt.data.cmd === "installed") {
              this.showNewVersionAvailableToast();
            }
          });
        }
      }
    },
    showNewVersionAvailableToast() {
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      Vue.toasted.show(
        "Eine neue Version der SKÖ-Internetseite wird automatisch installiert 🚀",
        {
          theme: "primary",
          position: "bottom-right",
          duration: null,
          fullWidth: false,
          className: "toast-update",
          action: null,
        }
      );
    },
  },
};
</script>

<style>
body {
  font-family: "Libre Franklin", sans-serif !important;
}

.toasted.primary {
  color: #fff;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 0.28571429rem !important;
  background: var(--green);
  font-weight: 600;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
}
.toasted.primary.action {
  border-radius: 4px;
  background: var(--green);
}
.toast-update.primary.default {
  color: var(--blue);
  border-radius: 4px;
  background: white;
}
.toast-update.primary.default a {
  color: #4183c4;
}
</style>
