<template>
  <div class="w-row">
    <!-- [START] Content -->
    <div class="column w-col w-col-8">
      <div class="div-block-7" style="margin-bottom: 30px; display: none">
        <div
          class="ui message"
          style="
            color: #2185d0;
            background-color: rgba(33, 133, 208, 0.05);
            border: 1px solid #2185d0;
          "
        >
          <h4 class="header">COVID-19 Maßnahmen beim SKÖ</h4>
          <p style="color: #156eb1">
            Bitte beachtet die Informationen zu den Corona-bedingten
            Schutzmaßnahmen, die wir fortlaufend aktualisieren, um einen
            sicheren Ablauf unserer Veranstaltungen zu gewährleisten.
          </p>
          <a
            href="neuigkeiten/Y1XR7COUcODTklGLjLX7/covid-19-massnahmen-beim-skoe"
            class="ui compact primary button"
            style="margin-top: 5px"
          >
            Mehr erfahren
            <i class="right arrow icon"></i>
          </a>
        </div>
      </div>
      <div class="div-block-7">
        <div class="block-headline block-headline-news">Neuigkeiten</div>
        <PostList />
      </div>
    </div>
    <!-- [END] Content -->
    <!-- [START] Sidebar -->
    <div class="column w-col w-col-4">
      <div class="block-headline">Termine</div>
      <EventList isHome />
      <div class="block-headline">Laufende Angebote</div>
      <CourseList />

      <router-link
        :to="{ name: 'events' }"
        class="button basic uppercase fluid centered"
        >Alle Termine ansehen</router-link
      >
    </div>
    <!-- [END] Sidebar -->
  </div>
</template>

<script>
import PostList from "@/views/PostList";
import EventList from "@/views/EventList";
import CourseList from "@/views/CourseList";

export default {
  name: "home",
  metaInfo: {
    title: "Startseite",
  },
  components: {
    PostList,
    EventList,
    CourseList,
  },
  mounted() {
    this.$store.dispatch("event/setRef");
  },
};
</script>
