<template>
  <div
    data-collapse="medium"
    data-animation="default"
    data-duration="400"
    class="navbar w-nav"
  >
    <div class="div-block-8">
      <nav role="navigation" class="nav-menu w-clearfix w-nav-menu">
        <router-link
          v-if="$route.name != 'home'"
          :to="{ name: 'home' }"
          class="nav-link nav-first-link w-nav-link"
          style="margin-top: -5px; margin-right: 20px"
          ><img src="@/assets/images/logo.png" width="30"
        /></router-link>

        <router-link
          :to="{ name: 'about' }"
          class="nav-link nav-first-link w-nav-link"
          >Über den Verein</router-link
        >
        <div
          class="ui dropdown item nav-link w-nav-link"
          style="font-size: inherit !important"
        >
          <span @click="onClickActivities">Angebote</span>
          <div class="menu">
            <router-link :to="{ name: 'mountainbike' }" class="item"
              >Mountainbiken</router-link
            >
            <router-link :to="{ name: 'nordic-walking' }" class="item"
              >Nordic Walking</router-link
            >
            <router-link :to="{ name: 'dancing' }" class="item"
              >Tanzgruppen</router-link
            >
            <router-link :to="{ name: 'fit-for-snow' }" class="item"
              >Fit for Snow</router-link
            >
            <router-link :to="{ name: 'skischool' }" class="item"
              >Ski-/und Snowboardschule</router-link
            >
            <router-link :to="{ name: 'ski-holidays' }" class="item"
              >Skifreizeiten</router-link
            >
            <router-link :to="{ name: 'excursions' }" class="item"
              >Ausflüge</router-link
            >
          </div>
        </div>
        <router-link :to="{ name: 'events' }" class="nav-link w-nav-link"
          >Termine</router-link
        >
        <router-link :to="{ name: 'member' }" class="nav-link w-nav-link"
          >Mitgliedschaft</router-link
        >
        <a href="https://shop.skiklub-oestertal.de" class="nav-link w-nav-link"
          >Shop</a
        >
        <router-link :to="{ name: 'contact' }" class="nav-link w-nav-link"
          >Kontakt</router-link
        >
        <router-link
          :to="{ name: 'profile' }"
          class="nav-link nav-link-profile w-nav-link"
          >Mein Profil</router-link
        >
      </nav>

      <div
        class="logo--mobile"
        style="float: right; margin-top: 14px; padding-right: 15px"
        v-if="$route.name != 'home'"
      >
        <router-link :to="{ name: 'home' }">
          <img src="@/assets/images/logo.png" width="30" />
        </router-link>
      </div>
      <div
        class="menu-button w-nav-button"
        style="float: left !important; width: 70%"
        @click="openMenu"
        :class="{ 'nav--active': isMobileMenuVisible }"
      >
        <div class="div-block-12" style="float: left; width: 50% !important">
          <div class="icon w-icon-nav-menu"></div>
          <div class="text-block-9">Menü</div>
        </div>
      </div>
    </div>
    <div class="w-nav-overlay" style="display: none" @click="closeMenu">
      <nav
        role="navigation"
        class="nav-menu w-clearfix w-nav-menu w--nav-menu-open"
      >
        <router-link
          :to="{ name: 'home' }"
          class="nav-link w-nav-link w--nav-link-open"
          >Startseite</router-link
        >
        <router-link
          :to="{ name: 'about' }"
          class="nav-link w-nav-link w--nav-link-open"
          >Über den Verein</router-link
        >
        <div class="ui dropdown item nav-link w-nav-link w--nav-link-open">
          <span @click="onClickActivities">Angebote</span>
          <div class="menu">
            <router-link :to="{ name: 'mountainbike' }" class="item"
              >Mountainbiken</router-link
            >
            <router-link :to="{ name: 'nordic-walking' }" class="item"
              >Nordic Walking</router-link
            >
            <router-link :to="{ name: 'dancing' }" class="item"
              >Tanzgruppen</router-link
            >
            <router-link :to="{ name: 'fit-for-snow' }" class="item"
              >Fit for Snow</router-link
            >
            <router-link :to="{ name: 'skischool' }" class="item"
              >Ski-/und Snowboardschule</router-link
            >
            <router-link :to="{ name: 'ski-holidays' }" class="item"
              >Skifreizeiten</router-link
            >
            <router-link :to="{ name: 'excursions' }" class="item"
              >Ausflüge</router-link
            >
          </div>
        </div>
        <router-link
          :to="{ name: 'events' }"
          class="nav-link w-nav-link w--nav-link-open"
          >Termine</router-link
        >
        <router-link
          :to="{ name: 'member' }"
          class="nav-link w-nav-link w--nav-link-open"
          >Mitgliedschaft</router-link
        >
        <a href="https://shop.skiklub-oestertal.de" class="nav-link w-nav-link"
          >Shop</a
        >
        <router-link
          :to="{ name: 'contact' }"
          class="nav-link w-nav-link w--nav-link-open"
          >Kontakt</router-link
        >
        <router-link
          :to="{ name: 'profile' }"
          class="nav-link nav-link-profile w-nav-link"
          style="float: left; margin-left: 20px; margin-bottom: 20px"
          >Mein Profil</router-link
        >
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "navBar",
  mounted() {
    $(".ui.dropdown").dropdown({
      on: "hover",
      action: "select",
    });
  },
  data() {
    return {
      isMobileMenuVisible: false,
    };
  },
  methods: {
    onClickActivities() {
      this.$router.push({ name: "activities" });
    },
    openMenu() {
      if ($(".w-nav-overlay").is(":visible")) {
        $(".w-nav-overlay").css({ display: "none" });
        this.isMobileMenuVisible = false;
      } else {
        $(".w-nav-overlay").css({ display: "block", height: "3909.19px" });
        this.isMobileMenuVisible = true;
      }
    },
    closeMenu() {
      $(".w-nav-overlay").css({ display: "none" });
      this.isMobileMenuVisible = false;
    },
  },
};
</script>

<style>
.ui.dropdown .menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid #efefef;
}
.nav-link-profile.active {
  border-color: #2185d0 !important;
  color: #2185d0 !important;
}
.nav--active {
  opacity: 0.7;
}
</style>
