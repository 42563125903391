import { db } from "@/firebase";
import { firestoreAction } from "vuexfire";

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  loading: true,
  list: [],
};

/**
 * Getters
 */
export const getters = {
  loading: state => state.loading,
  list: state => state.list,
  featured: state => {
    if (state.list.length > 0) {
      return state.list[0];
    }
  },
  postBySlug: state => slug => {
    return state.list.find(post => {
      return post.slug === slug;
    });
  },
  relatedPostsBySlug: state => slug => {
    return state.list.filter((post, index) => {
      return post.slug !== slug && index <= 3;
    });
  },
};

/**
 * Actions
 */
export const actions = {
  setRef: firestoreAction(async ({ bindFirestoreRef, commit, getters }) => {
    return Promise.resolve().then(async () => {
      if (getters.list && getters.list.length > 0) {
        bindFirestoreRef(
          "list",
          db
            .collection("posts")
            .where("visibility", "==", "published")
            .where("created", "<=", new Date())
            .orderBy("created", "desc")
            .limit(getters.list.length + 5),
          {
            reset: () => false,
          }
        ).then(() => commit("SET_LOADING", false));
      } else {
        commit("SET_LOADING", true);

        bindFirestoreRef(
          "list",
          db
            .collection("posts")
            .where("visibility", "==", "published")
            .where("created", "<=", new Date())
            .orderBy("created", "desc")
            .limit(5),
          {
            reset: () => false,
          }
        ).then(() => commit("SET_LOADING", false));
      }
    });
  }),

  async getById({ commit }, { postId }) {
    return Promise.resolve().then(async () => {
      commit("SET_LOADING", true);

      var docRef = db.collection("posts").doc(postId);
      var post;

      await docRef
        .get()
        .then(doc => {
          if (doc.exists) {
            post = doc.data();
          }
        })
        .catch(error => {
          console.log("Error getting document:", error);
        });

      commit("SET_LOADING", false);

      return post;
    });
  },

  getNextPosts({ dispatch }) {
    return dispatch("setRef");
  },
};

/**
 * Mutations
 */
export const mutations = {
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
};
