import { firebase } from "@firebase/app";
import { firestoreAction } from "vuexfire";
import { db } from "@/firebase";
import "@firebase/auth";
firebase.auth().languageCode = "de";

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  loading: false,
  current: null,
};

/**
 * Getters
 */
export const getters = {
  loading: state => state.loading,
  current: state => state.current,
};

/**
 * Actions
 */
export const actions = {
  /**
   * Add subscription for data from Firebase
   */
  setRef: firestoreAction(async ({ bindFirestoreRef, commit }) => {
    const user = await firebase.auth().currentUser;

    if (!user) {
      return null;
    }

    commit("SET_LOADING", true);

    bindFirestoreRef("current", db.collection("users").doc(user.uid), {
      reset: () => false,
    })
      .catch(error => console.error("Error receiving user", error))
      .finally(() => commit("SET_LOADING", false));
  }),

  /**
   * Registers user with Firebase
   * @param {*} param0
   * @param {*} param1
   */
  registerWithEmail({ dispatch }, { email, password }) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(response =>
          resolve(
            dispatch("setRef", response.user.uid).then(() => {
              const user = firebase.auth().currentUser;
              return user.sendEmailVerification();
            })
          )
        )
        .catch(err => reject(err));
    });
  },

  /**
   * Signs in existing email user with Firebase
   * @param {*} param0
   * @param {*} param1
   */
  loginWithEmail({ dispatch }, { email, password }) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(response => resolve(dispatch("setRef", response.user.uid)))
        .catch(err => reject(err));
    });
  },

  /**
   * Sends a password recovery email to the corresponding user
   * @param {*} param0
   * @param {*} param1
   */
  sendRecoverPasswordEmail({ commit }, { email }) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => resolve(commit("resetUser")))
        .catch(error => reject(error));
    });
  },

  updateProfile: firestoreAction((_, { user, property, value }) => {
    return new Promise((resolve, reject) => {
      var localUser = firebase.auth().currentUser;
      if (localUser.uid != user.uid) {
        throw new Error("User not allowed to update profile");
      }

      const update = {};
      update[`${property}`] = value ? value : false;
      update["updated"] = firebase.firestore.FieldValue.serverTimestamp();

      return db
        .collection("users")
        .doc(user.uid)
        .update(update)
        .then(() => resolve())
        .catch(error => {
          console.error("Error updating profile", error);
          reject(error);
        });
    });
  }),

  /**
   * Logs out the current user from Firebase
   * @param {*} param0
   */
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => resolve(commit("resetUser")))
        .catch(err => reject(err));
    });
  },

  /**
   * DEletes the current user from Firebase
   * @param {*} param0
   */
  delete({ commit }, { password }) {
    return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        password
      );

      return user
        .reauthenticateWithCredential(credential)
        .then(() => user.delete())
        .then(() => resolve(commit("resetUser")))
        .catch(err => reject(err));
    });
  },
};

/**
 * Mutations
 */
export const mutations = {
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },

  /**
   * Sets the current firebase user
   * @param {*} state
   * @param {*} user
   */
  setUser(state, user) {
    state.current = {
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      isAnonymous: user.isAnonymous,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
      id: user.uid,
    };
  },

  /**
   * Resets the current user
   * @param {*} state
   */
  resetUser(state) {
    state.current = null;
  },
};
