import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/performance";

// Initialize Firebase SDK
var firebaseConfig = {
  apiKey: "AIzaSyD28NxGVkzQb1R6K5zpg-nISaqvDLBgNdk",
  authDomain: "skiklub-oestertal-web.firebaseapp.com",
  databaseURL: "https://skiklub-oestertal-web.firebaseio.com",
  projectId: "skiklub-oestertal-web",
  storageBucket: "skiklub-oestertal-web.appspot.com",
  messagingSenderId: "478086009810",
  appId: "1:478086009810:web:ffb50a344b2d4f49",
  measurementId: "G-5NP8RXP3SN",
};

export const db = firebase.initializeApp(firebaseConfig).firestore();
firebase.analytics();
firebase.performance();

// Enable offline storage
firebase
  .firestore()
  .enablePersistence({
    synchronizeTabs: true,
  })
  .catch(function (err) {
    if (err.code == "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

// Export types that exists in Firestore
export const { Timestamp, GeoPoint } = firebase.firestore;
