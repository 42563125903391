import Vue from "vue";
import Router from "vue-router";
import { firebase } from "@firebase/app";
import Home from "./pages/Home.vue";
import "@firebase/auth";

Vue.use(Router);

const scrollBehavior = () => {
  return {
    x: 0,
    y: 0,
  };
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior,
  linkExactActiveClass: "w--current active",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        scrollToTop: true,
      },
    },
    {
      path: "/neuigkeiten",
      name: "posts",
      meta: {
        scrollToTop: true,
        layout: "no-sidebar",
      },
      component: () =>
        import(/* webpackChunkName: "news" */ "./pages/Posts.vue"),
    },
    {
      path: "/neuigkeiten/:id?/:slug?",
      name: "post",
      meta: {
        scrollToTop: true,
        layout: "no-sidebar",
      },
      component: () =>
        import(/* webpackChunkName: "news" */ "./pages/Post.vue"),
    },
    {
      path: "/ueber-den-verein",
      name: "about",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(/* webpackChunkName: "about" */ "./pages/About.vue"),
    },
    {
      path: "/angebote",
      name: "activities",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(/* webpackChunkName: "activities" */ "./pages/Activities.vue"),
    },
    {
      path: "/angebote/mountainbiken",
      name: "mountainbike",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(
          /* webpackChunkName: "activities" */ "./pages/Activities/Mountainbike.vue"
        ),
    },
    {
      path: "/angebote/tanzgruppen",
      name: "dancing",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(
          /* webpackChunkName: "activities" */ "./pages/Activities/Dancing.vue"
        ),
    },
    {
      path: "/angebote/ausfluege",
      name: "excursions",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(
          /* webpackChunkName: "activities" */ "./pages/Activities/Excursions.vue"
        ),
    },
    {
      path: "/angebote/fit-for-snow",
      name: "fit-for-snow",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(
          /* webpackChunkName: "activities" */ "./pages/Activities/FitForSnow.vue"
        ),
    },
    {
      path: "/angebote/nordic-walking",
      name: "nordic-walking",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(
          /* webpackChunkName: "activities" */ "./pages/Activities/NordicWalking.vue"
        ),
    },
    {
      path: "/angebote/skifreizeiten",
      name: "ski-holidays",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(
          /* webpackChunkName: "activities" */ "./pages/Activities/SkiHolidays.vue"
        ),
    },
    {
      path: "/angebote/ski-und-snowboardschule",
      name: "skischool",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(
          /* webpackChunkName: "activities" */ "./pages/Activities/SkiSchool.vue"
        ),
    },
    {
      path: "/termine",
      name: "events",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(/* webpackChunkName: "events" */ "./pages/Events.vue"),
    },
    {
      path: "/termine/:id/:slug?",
      name: "event",
      meta: {
        scrollToTop: true,
        layout: "no-sidebar",
      },
      component: () =>
        import(/* webpackChunkName: "events" */ "./pages/Event.vue"),
    },
    {
      path: "/mitglied-werden",
      name: "member",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(/* webpackChunkName: "member" */ "./pages/Member.vue"),
    },
    {
      path: "/kontakt",
      name: "contact",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(/* webpackChunkName: "contact" */ "./pages/Contact.vue"),
    },
    {
      path: "/datenschutz",
      name: "data-policy",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(/* webpackChunkName: "data-policy" */ "./pages/DataPolicy.vue"),
    },
    {
      path: "/nutzungsbedingungen",
      name: "terms",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(/* webpackChunkName: "terms" */ "./pages/Terms.vue"),
    },
    {
      path: "/impressum",
      name: "imprint",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(/* webpackChunkName: "imprint" */ "./pages/Imprint.vue"),
    },
    {
      path: "/profil",
      name: "profile",
      meta: {
        layout: "no-sidebar",
        requiresAuth: true,
      },
      component: () =>
        import(/* webpackChunkName: "profile" */ "./pages/Profile.vue"),
    },
    {
      path: "/profil/anmelden",
      name: "login",
      meta: {
        layout: "no-sidebar",
        requiresGuest: true,
      },
      component: () =>
        import(/* webpackChunkName: "profile" */ "./pages/profile/Login.vue"),
    },
    {
      path: "/profil/registrieren",
      name: "register",
      meta: {
        layout: "no-sidebar",
        requiresGuest: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "profile" */ "./pages/profile/Register.vue"
        ),
    },
    {
      path: "/profil/passwort-zurücksetzen",
      name: "recover-password",
      meta: {
        layout: "no-sidebar",
        requiresGuest: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "profile" */ "./pages/profile/RecoverPassword.vue"
        ),
    },
    {
      path: "/profil/profil-löschen",
      name: "delete-profile",
      meta: {
        layout: "no-sidebar",
        requiresAuth: true,
      },
      component: () =>
        import(/* webpackChunkName: "profile" */ "./pages/profile/Delete.vue"),
    },
    {
      path: "/mtb",
      name: "mtb-guides",
      meta: {
        layout: "no-sidebar",
      },
      component: () =>
        import(/* webpackChunkName: "guides" */ "./pages/GuidesMtb.vue"),
    },
    {
      path: "*",
      name: "404",
      meta: {
        scrollToTop: true,
        layout: "no-sidebar",
      },
      component: () => import(/* webpackChunkName: "404" */ "./pages/404.vue"),
    },
  ],
});

// Protect routes from unauthorized access
router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresGuest = to.matched.some(record => record.meta.requiresGuest);

  if (requiresAuth && !currentUser) {
    next({ name: "login" });
  } else if (requiresGuest && currentUser) {
    next({ name: "profile" });
  } else {
    next();
  }
});

export default router;
